import React, { useState, useContext } from "react"

import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

import { ModalContext, PetDataContext } from "../../app"

const validationSchema = Yup.object({
  emailAddress: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
})

function LoginForm(props) {
  const [passwordShown, setPasswordShown] = useState(false);

  const modalContext = useContext(ModalContext)
  const petDataContext = useContext(PetDataContext)


  const handleSubmit = values => {
    props.makeLoginRequest(values)
    props.setEmail(values.emailAddress)
    props.setNewUserReq(false);
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown)
  }

  return (
    <>
      <figure className="better-world-pets-logo mx-auto">
        <img
          className="img-fluid"
          src="../../../better-world-pets-logo.png"
          alt=""
        />
      </figure>
      <ul className="nav nav-tabs icons">
        <li className="nav-item">
          <a className="nav-link active">Log in</a>
        </li>
        <li
          className="nav-item cursor-pointer"
          onClick={() => {
            modalContext.dispatch("hideLoginModal");
            modalContext.dispatch("showSignupModal");
          }}
        >
          <a className="nav-link">Create profile</a>
        </li>
      </ul>
      <p className="caption text-mars-blue">
        All fields marked with an asterisk (
        <span className="text-mars-red">*</span>) are required
      </p>
      {props.credentialsError !== "" &&
        props.credentialsError !==
          "Please verify your email before logging in." && (
          <div className="alert alert-danger icon icon-failed" role="alert">
            {props.credentialsError}
          </div>
        )}

      {props.credentialsError ===
        "Please verify your email before logging in." && (
        <p className="caption text-mars-red alert alert-danger icon icon-failed">
          {/* {isNewUserRequestContext.state.userAlreadyExists} */}
          Please verify your email before logging in. If you haven't received an
          email, click{" "}
          <span
            className="text-mars-red cursor-pointer text-decoration-underline"
            onClick={() => {
              // modalContext.dispatch("showLoginModal");
              props.makeResendEmailRequest(props.email);
              props.setResendEmail(true);
              // prop
            }}
          >
            here
          </span>{" "}
          to resend it.
        </p>
      )}
      {
        props.newUserReq &&
        <p className="caption text-mars-red alert alert-danger icon icon-failed">
          {/* {isNewUserRequestContext.state.userAlreadyExists} */}
          Account doesn't exists. Please click{" "}
          <span
            className="text-mars-red cursor-pointer text-decoration-underline"
            onClick={() => {
              modalContext.dispatch("hideLoginModal")
              modalContext.dispatch("showSignupModal")
            }}
          >
            here
          </span>{" "}
          to signup.
        </p>
      }

      {props.inCorrectEmail && (
        <div className="alert alert-danger icon icon-failed" role="alert">
          Incorrect email
        </div>
      )}
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          return (
            <>
              <Form id="myform" autoComplete="off">
                <div
                  className={`form-input-hldr ${
                    (formik.touched.emailAddress &&
                      formik.errors.emailAddress) ||
                    props.credentialsError !== "" ||
                    props.inCorrectEmail || props.newUserReq
                      ? "input-error"
                      : null
                  }`}
                >
                  <label htmlFor="emailAddress" className="form-label">
                    Email address*
                  </label>

                  <Field name="emailAddress">
                    {({ form, field }) => {
                      const { setFieldValue, setFieldTouched } = form;
                      const { value } = field;
                      return (
                        <>
                          <input
                            type="text"
                            className="form-control"
                            id="emailAddress"
                            placeholder="Email address"
                            value={value}
                            onChange={(e) => {
                              setFieldValue("emailAddress", e.target.value);
                              props.setCredentialsError("");
                              props.setNewUserReq(false);
                              props.setInCorrectEmail(false);
                            }}
                            onBlur={() => {
                              setFieldTouched("emailAddress");
                            }}
                          />
                        </>
                      );
                    }}
                  </Field>
                </div>

                <div
                  className={`form-input-hldr ${
                    (formik.touched.password && formik.errors.password) ||
                    props.credentialsError !== "" || props.newUserReq
                      ? "input-error"
                      : null
                  }`}
                >
                  <label htmlFor="password" className="form-label">
                    Password*{" "}
                  </label>
                  <div className="toggle-password-hldr">
                    <Field name="password">
                      {({ form, field }) => {
                        const { setFieldValue, setFieldTouched } = form;
                        const { value } = field;
                        return (
                          <>
                            <input
                              type={passwordShown ? "text" : "password"}
                              className="form-control"
                              id="password"
                              placeholder="Password"
                              value={value}
                              onChange={(e) => {
                                setFieldValue("password", e.target.value);
                                props.setCredentialsError("");
                                props.setNewUserReq(false);
                              }}
                              onBlur={() => {
                                setFieldTouched("password");
                              }}
                            />
                            <span
                              className={`toggle-password ${
                                passwordShown ? "on" : ""
                              }`}
                              onClick={togglePassword}
                            ></span>
                          </>
                        );
                      }}
                    </Field>
                    <span
                      className={`toggle-password ${passwordShown ? "on" : ""}`}
                      onClick={togglePassword}
                    ></span>
                  </div>
                </div>
                <span
                  className="d-inline-block btn-text"
                  onClick={() => {
                    props.setResendEmail(false);
                    props.setCredentialsError("");
                    if (formik.values.emailAddress === "") {
                      formik.validateField("emailAddress");
                      formik.setFieldTouched("emailAddress");
                    } else if (!formik.errors.emailAddress) {
                      props.setData({
                        ...props.data,
                        emailAddress: formik.values.emailAddress,
                      });
                      props.makeIsNewUserRequest(
                        formik.values.emailAddress
                      );
                    }
                  }}
                >
                  Forgot password?
                </span>
              </Form>
            </>
          );
        }}
      </Formik>
    </>
  );
}

export default LoginForm
