import React, { useState, useContext } from "react";
import Modal from "react-bootstrap/Modal";

import axios from "axios";
import moment from "moment";

import LoginForm from "../forms/form-login/form-login";
// import OTPBox from "../forms/otp-box/otp-box"

import {
  ResetPasswordContext,
  IsLoginContext,
  LoginProfileDataContext,
  EmailRequestContext,
  LoadingContext,
  ModalContext,
  SnackbarContext,
  PetDataContext,
  FetchDataContext,
  AddedPetIdContext,
  IsNewUserRequestContext,
} from "../app";
import EmailVerification from "../forms/email-verification/email-verification";

const initialValues = {
  emailAddress: "",
  password: "",
};

function ModalLogin(props) {
  const [data, setData] = useState(initialValues);
  const [loginForm, setLoginForm] = useState(true);
  const [credentialsError, setCredentialsError] = useState("");
  const [passwordNotChanged, setPasswordNotChanged] = useState(false);
  const [forgotPasswordResponseData, setForgotPasswordResponseData] = useState(
    {}
  );
  const [verifyOTPResponse, setVerifyOTPResponse] = useState({});
  const [inCorrectEmail, setInCorrectEmail] = useState(false);
  const [OTPError, setOTPError] = useState("");
  const [resendEmail, setResendEmail] = useState(false);
  const [newUserReq, setNewUserReq] = useState(false);
  const [email, setEmail] = useState("");

  const isLogin = useContext(IsLoginContext);
  const loadingContext = useContext(LoadingContext);
  const resetPasswordContext = useContext(ResetPasswordContext);
  const loginProfileDataContext = useContext(LoginProfileDataContext);
  const petDataContext = useContext(PetDataContext);
  const emailRequestContext = useContext(EmailRequestContext);
  const modalContext = useContext(ModalContext);
  const snackbarContext = useContext(SnackbarContext);
  const fetchDataContext = useContext(FetchDataContext);
  const addedPetIdContext = useContext(AddedPetIdContext);
  const isNewUserRequestContext = useContext(IsNewUserRequestContext);

  const makeAddPuppyRequest = (response) => {
    loadingContext.setLoading(true);

    const dataToSend = {
      pet_name: petDataContext.state.petName,
      dob: moment(petDataContext.state.birthDate).format("YYYY-MM-DD"),
      do_you_know_your_pets_breed: petDataContext.state.knowPetsBreed,
      // breed: formData.mixedBreed === true ? null : formData.petsBreed,
      breed:
        petDataContext.state.species === "cat" &&
        petDataContext.state.mixedBreed
          ? null
          : petDataContext.state.petsBreed,
      mixed_breed: petDataContext.state.mixedBreed === true ? "yes" : null,
      breed_size: petDataContext.state.petsAdultWeight,
      sex: petDataContext.state.petsGender,
      neuter_date: null,
      species: petDataContext.state.species, //cat or dog allowed
    };
    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/add-pet?_queryFilter1=mail eq ${response.data.owner.email}&_queryFilter2=_id eq ${response.data.owner._id}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${response.data.owner.token}`,
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (response.data._id) {
          addedPetIdContext.setState(response.data.petID);
          fetchDataContext();
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Pet's profile was created successfully.",
          });
          petDataContext.setState({});
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  const makeLoginRequest = (formData) => {
    loadingContext.setLoading(true);
    const dataToSend = {
      email: formData.emailAddress,
      password: formData.password,
    };

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/login`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (
          response.data.error &&
          response.data.message.error_description === "Wrong email or password."
        ) {
          setCredentialsError("Incorrect email or password.");
        } else if (
          response.data.error &&
          response.data.message.error === "too_many_attempts"
        ) {
          setCredentialsError(
            "Your account has been blocked after multiple consecutive login attempts. We've sent you a notification via your preferred contact method with instructions on how to unblock it."
          );
        } else if (
          response.data.error &&
          response.data.message.error_description ===
            "Password change required."
        ) {
          setCredentialsError(
            "Password change required. Please reset your password."
          );
        } else if (
          response.data.error &&
          response.data.message.error_description ===
            "Please verify your email before logging in."
        ) {
          setCredentialsError("Please verify your email before logging in.");
        } else if (response.data.name === "Internal Server Error") {
          modalContext.dispatch("hideLoginModal");
          snackbarContext.setState({
            classes: "alert-danger icon-failed",
            content: response.data.message,
          });
        } else if (
          response.data.error &&
          response.data.message.errorSummary ===
            "You do not have permission to perform the requested action"
        ) {
          setCredentialsError(
            "Sorry! Something went wrong. Please try again later."
          );
        } else if (
          response.data.error &&
          response.data.message.message ===
            "User Verified but not submitted"
        ) {
          setCredentialsError(
            "Incorrect email or password."
          );
        } else {
          loginProfileDataContext.setState(response.data);
          modalContext.dispatch("hideLoginModal");
          isLogin.setIsLogin(true);
          localStorage.setItem("token", response.data.owner.token);
          if (Object.keys(petDataContext.state).length !== 0) {
            makeAddPuppyRequest(response);
          }
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };
  const makeForgotPasswordRequest = (emailAddress) => {
    loadingContext.setLoading(true);
    setOTPError("");
    const dataToSend = {
      email: emailAddress,
    };

    const config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/forgotpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (response.data.message && response.data.reason) {
          setCredentialsError("");
          setInCorrectEmail(true);
        } else {
          setLoginForm(false);
          setForgotPasswordResponseData(response.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  const makeIsNewUserRequest = (email) => {
    loadingContext.setLoading(true);
    const dataToSend = {
      email: email
    };

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/is-new-user`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (
          response.data.error === false &&
          response.data.message === "User Verified but not submitted"
        ) {
          isNewUserRequestContext.dispatch({
            type: "USER_VERIFIED_NOT_SUBMITTED",
            message: "",
          });
          // setCurrentStep((prev) => prev + 1);
        } else if (
          response.data.error === false &&
          response.data.message === "new user"
        ) {
          setNewUserReq(true)
        } else if (
          response.data.error === true &&
          response.data.message ===
            "Account already exists with this mail address. Kindly login!"
        ) {
          // isNewUserRequestContext.dispatch({
          //   type: "USER_EXISTS",
          //   message: response.data.message,
          // });
          makeForgotPasswordRequest(email)
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  const makeResendEmailRequest = (emailAddress) => {
    loadingContext.setLoading(true);
    const dataToSend = {
      email: emailAddress,
    };

    var config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/verification-email`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (response.data.message && response.data.reason) {
          setCredentialsError("");
          setInCorrectEmail(true);
        } else {
          setLoginForm(false);
          setForgotPasswordResponseData(response.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  // const makeVerifyOtpRequest = (
  //   OTPValue,
  //   setOTPMatched,
  //   setOTPNotMatched,
  //   setOTPResendEnable,
  //   setOTPTimerStart,
  //   setOTPTimerDuration
  // ) => {
  //   loadingContext.setLoading(true);
  //   const dataToSend = {
  //     verifyOTPAuthID: forgotPasswordResponseData.verifyOTPAuthID,
  //     verifyOTPCookie: forgotPasswordResponseData.verifyOTPCookie,
  //     otp: OTPValue,
  //   };

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-otp`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   };
  //   // Send Data to API
  //   axios(config)
  //     .then((response) => {
  //       // console.log(response)
  //       loadingContext.setLoading(false);
  //       if (response.data.message === "Login failure") {
  //         setOTPNotMatched(true);
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 2 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 2 more failure(s).");
  //         setOTPNotMatched(true);
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 1 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 1 more failure(s).");
  //         setOTPNotMatched(true);
  //       } else if (response.data.message === "User Locked Out.") {
  //         setOTPError(
  //           "Your account is temporarily locked. please retry in 5 minutes."
  //         );
  //         setOTPNotMatched(false);
  //         setOTPResendEnable(false);
  //         setOTPTimerStart(false);
  //         setOTPTimerDuration(30);
  //       } else if (
  //         response.data.callbacks[0].type === "ValidatedCreatePasswordCallback"
  //       ) {
  //         setOTPError("");
  //         setOTPMatched(true);
  //         setOTPNotMatched(false);
  //         setOTPResendEnable(false);
  //         setOTPTimerStart(false);
  //         setOTPTimerDuration(30);
  //         setVerifyOTPResponse(response.data);
  //       } else {
  //         setOTPNotMatched(true);
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       loadingContext.setLoading(false);
  //       setOTPNotMatched(true);
  //     });
  // };
  // const makeResetPasswordRequest = (formData) => {
  //   loadingContext.setLoading(true);
  //   const dataToSend = {
  //     resetPasswordAuthID: verifyOTPResponse.resetPasswordAuthID,
  //     resetPasswordCookie: verifyOTPResponse.resetPasswordCookie,
  //     password: formData.newPassword,
  //   };

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/reset-password`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   };
  //   // Send Data to API
  //   axios(config)
  //     .then((response) => {
  //       // console.log(response)
  //       loadingContext.setLoading(false);
  //       if (response.data.failedPolicies) {
  //         emailRequestContext.dispatch({
  //           type: "PASSWORD_FAILED",
  //           passwordErrorText: response.data.failedPolicies.password.message,
  //         });
  //         setPasswordNotChanged(true);
  //       } else if (response.data.tokenId) {
  //         emailRequestContext.dispatch({
  //           type: "OTP_SENT_SUCCESS",
  //           authId: response.data.authId,
  //           ownerEnterCookie: response.data.owner_enter_cookie,
  //         });
  //         setPasswordNotChanged(false);
  //         modalContext.dispatch("hideLoginModal");
  //         snackbarContext.setState({
  //           classes: "alert-success icon-success",
  //           content: "Your password was changed successfully.",
  //         });
  //       } else {
  //         setPasswordNotChanged(true);
  //       }
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       loadingContext.setLoading(false);
  //     });
  // };

  return (
    <>
      <Modal
        {...props}
        size="lg"
        centered
        animation
        scrollable
        onEnter={() => {
          resetPasswordContext.setState(true);
        }}
        onExited={() => {
          setLoginForm(true);
          setData(initialValues);
          resetPasswordContext.setState(false);
          setCredentialsError("");
        }}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title as="h6" className="text-mars-blue">
            {(!loginForm && !resendEmail )? "Reset password" : "User profile"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loginForm ? (
            <LoginForm
              data={data}
              setData={setData}
              email={email}
              setEmail={setEmail}
              setLoginForm={setLoginForm}
              setResendEmail={setResendEmail}
              makeLoginRequest={makeLoginRequest}
              makeIsNewUserRequest={makeIsNewUserRequest}
              credentialsError={credentialsError}
              setCredentialsError={setCredentialsError}
              newUserReq={newUserReq}
              setNewUserReq={setNewUserReq}
              inCorrectEmail={inCorrectEmail}
              setInCorrectEmail={setInCorrectEmail}
              makeForgotPasswordRequest={makeForgotPasswordRequest}
              makeResendEmailRequest={makeResendEmailRequest}
            />
          ) : (
            // <OTPBox
            //   emailAddress={data.emailAddress}
            //   forgotPassword={true}
            //   title={
            //     "To complete password reset process, we need to verify your email."
            //   }
            //   makeForgotPasswordRequest={makeForgotPasswordRequest}
            //   makeVerifyOtpRequest={makeVerifyOtpRequest}
            //   makeResetPasswordRequest={makeResetPasswordRequest}
            //   passwordNotChanged={passwordNotChanged}
            //   setPasswordNotChanged={setPasswordNotChanged}
            //   OTPError={OTPError}
            //   setOTPError={setOTPError}
            // />
            <EmailVerification
              emailAddress={data.emailAddress}
              forgotPassword={true}
              email={email}
              title={ resendEmail ? "Kindly verify your email address before logging in." :
                "To complete password reset process, we need to verify your email."
              }
              makeForgotPasswordRequest={makeForgotPasswordRequest}
              // makeResetPasswordRequest={makeResetPasswordRequest}
              passwordNotChanged={passwordNotChanged}
              resendEmail={resendEmail}
              setResendEmail={setResendEmail}
              setPasswordNotChanged={setPasswordNotChanged}
              makeResendEmailRequest={makeResendEmailRequest}
            />
          )}
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between">
          {loginForm ? (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={props.onHide}
              >
                Close
              </button>
            </>
          ) : (
            <>
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={() => setLoginForm(true)}
              >
                Back
              </button>
            </>
          )}
          {loginForm ? (
            <button type="submit" className="btn btn-mars-blue" form="myform">
              Log in
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-mars-blue"
              onClick={props.onHide}
            >
              Close
            </button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalLogin;
