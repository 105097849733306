import React from "react"

function PageHeading() {
  return (
    <>
      <div className="page-heading d-flex justify-content-between align-items-center">
        <h5 className="text-mars-blue mb-0">
          WALTHAM<sup>TM</sup> Digital Growth Chart
        </h5>
        <div className="export-pdf-element">
          <div className="text-mars-red body-1 m-0 d-flex justify-content-center align-items-center">
            <figure className="mb-0 me-1">
              <img src="../../../icons/icon-internet.png" alt="" />
            </figure>
            <span>waltham.com/resources/puppy-growth-charts</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default PageHeading
