import React, { useState, useEffect, useContext } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { Navigation } from "swiper"
import moment from "moment"
import useWindowWidth from "react-hook-use-window-width"

import "react-loading-skeleton/dist/skeleton.css"

import {
  ModalContext,
  LoginProfileDataContext,
  SkeletonLoadingContext,
  SnackbarContext,
  GrowthChartSnackbarContext,
  TabInitialKeyContext,
  AddedPetIdContext,
} from "../app"
import {
  CurrentPetDataContext,
  InitialSlideContext,
  UndoContext,
  UndoCloseContext,
  ToggleConfidenceMessageSnackbarContext,
  ToggleGrowthGuidanceSnackbarContext,
} from "../templates/homepage/homepage"
import PuppyListSkeleton from "../loading-indicators/puppy-list-skeleton"
import NoPetsFound from "./helpers/no-pets-found"

// import puppies from "../../jsons/puppies.json"

const PuppyListCarousel = ({ searchPets }) => {
  const skeletonLoadingContext = useContext(SkeletonLoadingContext)
  const modalContext = useContext(ModalContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const initialSlideContext = useContext(InitialSlideContext)
  const snackbarContext = useContext(SnackbarContext)
  const growthChartSnackbarContext = useContext(GrowthChartSnackbarContext)
  const toggleConfidenceMessageSnackbarContext = useContext(
    ToggleConfidenceMessageSnackbarContext
  )
  const toggleGrowthGuidanceSnackbarContext = useContext(
    ToggleGrowthGuidanceSnackbarContext
  )
  const addedPetIdContext = useContext(AddedPetIdContext)
  const undoContext = useContext(UndoContext)
  const undoCloseContext = useContext(UndoCloseContext)
  const tabInitialKeyContext = useContext(TabInitialKeyContext)

  const [swiper, setSwiper] = useState()

  let puppies = loginProfileDataContext.state.pets

  puppies = puppies.sort((a, b) => new Date(b.dob) - new Date(a.dob))

  const width = useWindowWidth()

  let transitionSize
  if (width >= 992) {
    transitionSize = 216
  } else {
    transitionSize = 112
  }

  let filteredPuppies = puppies.filter(val => {
    if (searchPets.searchTerm === "" && searchPets.petSpecies === "") {
      return val
    } else if (
      val.pet_name.toLowerCase().includes(searchPets.searchTerm.toLowerCase())
    ) {
      return val.species
        .toLowerCase()
        .includes(searchPets.petSpecies.toLowerCase())
    }
  })

  let index

  useEffect(() => {
    index =
      addedPetIdContext.state !== ""
        ? filteredPuppies.findIndex(
            puppy => puppy.petID === addedPetIdContext.state
          )
        : -1
    if (index !== -1) {
      swiper?.slideTo(index)
    }
  })

  return (
    <>
      <div className="export-pdf-element puppy-block">
        <div className="puppy-card__block active">
          <h6 className="text-mars-blue">
            {currentPetDataContext.state.pet_name}
          </h6>
          <p className="body-2 mb-0">{currentPetDataContext.state.breed}</p>

          <p className="caption mb-0">
            {currentPetDataContext.state.breed_size}
          </p>

          <div className="d-flex flex-wrap justify-content-between align-items-center extra-info">
            <div>
              <div className="d-flex align-items-center">
                <figure className="flex-shrink-0 mb-0">
                  <img src="../../../icons/icon-calendar.svg" alt="" />
                </figure>
                <div>
                  <p className="subtitle-2 mb-0">Date of Birth</p>
                  <p className="body-2 mb-0">
                    {moment(currentPetDataContext.state.dob).format(
                      "DD MMM YYYY"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <figure className="flex-shrink-0 mb-0">
                  <img
                    src={`../../../icons/icon-gender-${
                      currentPetDataContext.state.sex === "Male"
                        ? "male"
                        : "female"
                    }.svg`}
                    alt=""
                  />
                </figure>
                <div>
                  <p className="subtitle-2 mb-0">Sex</p>
                  <p className="body-2 mb-0">
                    {currentPetDataContext.state.sex}
                  </p>
                </div>
              </div>
            </div>
            <div>
              <div className="d-flex align-items-center">
                <figure className="flex-shrink-0 mb-0">
                  <img src="../../../icons/icon-weight.svg" alt="" />
                </figure>

                <div>
                  <p className="subtitle-2 mb-0">Last weight added</p>
                  <p className="body-2 mb-0">
                    {currentPetDataContext.state.weights.weights.length !== 0
                      ? currentPetDataContext.state.weights.weights[
                          currentPetDataContext.state.weights.weights.length - 1
                        ].weightKg.toFixed(2)
                      : 0}{" "}
                    kg
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="puppy-card-list">
        {/* {skeletonLoadingContext.skeletonLoading ? (
          <PuppyListSkeleton />
        ) : ( */}
        <Swiper
          direction="vertical"
          slidesPerView="auto"
          initialSlide={index !== -1 ? index : initialSlideContext.state}
          slideToClickedSlide={true}
          centeredSlides={true}
          spaceBetween={8}
          // observer={true}
          // observeParents={true}
          autoHeight
          // navigation={{
          //   nextEl: ".swiper-button-next",
          //   prevEl: ".swiper-button-prev",
          // }}
          modules={[Navigation]}
          className="puppy-card-swiper"
          onSwiper={swiper => {
            setSwiper(swiper)
            toggleConfidenceMessageSnackbarContext.setState(false)
            toggleGrowthGuidanceSnackbarContext.setState(false)
            if (undoCloseContext.state) {
              undoContext.setState(true)
            }
            if (swiper) {
              initialSlideContext.setState(swiper.realIndex)

              if (swiper.slides.length === 1) {
                currentPetDataContext.setState({
                  weights: {
                    weights: [],
                  },
                })
              }
            }
          }}
          onSlideChange={() => {
            addedPetIdContext.setState("")
            undoContext.setState(false)
            toggleConfidenceMessageSnackbarContext.setState(false)
            toggleGrowthGuidanceSnackbarContext.setState(false)
            if (swiper) {
              if (swiper.slides) {

                initialSlideContext.setState(swiper.realIndex)
                if (swiper.realIndex === swiper.slides.length - 1) {
                  currentPetDataContext.setState({
                    weights: {
                      weights: [],
                    },
                  })
                }
              }
            }
          }}
          breakpoints={{
            // when window width is <= 991px
            992: {
              direction: "horizontal",
              spaceBetween: 16,
              allowTouchMove: false,
              // modules:{[Navigation]},
              // navigation: {
              //   nextEl: ".swiper-button-next",
              //   prevEl: ".swiper-button-prev",
              // },
            },
          }}
        >
          {filteredPuppies.map(puppy => {
            let currentPuppy, filteredCurrentPuppy
            if (puppy.weights.weights.length !== 0) {
              currentPuppy = puppy.weights.weights
              filteredCurrentPuppy = currentPuppy.sort(
                (a, b) => b.ageWeeks - a.ageWeeks
              )
            }

            return (
              <>
                <SwiperSlide key={puppy.petID}>
                  {({ isActive }) => {
                    {
                      isActive && currentPetDataContext.setState(puppy)
                    }

                    return (
                      <>
                        <div
                          className={`puppy-card__block ${
                            isActive ? "active" : ""
                          }`}
                        >
                          <h6 className="text-mars-blue">{puppy.pet_name}</h6>
                          <p className="body-2 mb-0">
                            {puppy.breed === "null" || null ? "" : puppy.breed}
                          </p>
                          <p className="caption text-end mb-0 adult-weight d-lg-none">
                            {/* 15.0 – 30.0 kg
                          <br /> adult weight */}
                            {puppy.breed_size}
                          </p>
                          <p className="caption mb-0">{puppy.breed_size}</p>
                          <div
                            className="d-flex align-items-center edit"
                            onClick={() => {
                              modalContext.dispatch(
                                "showUpdatePetsProfileModal"
                              )
                            }}
                            onTouchEnd={e => {
                              e.preventDefault()
                              modalContext.dispatch(
                                "showUpdatePetsProfileModal"
                              )
                            }}
                          >
                            <figure className="mb-0">
                              <img src="../../../icons/icon-edit.svg" alt="" />
                            </figure>
                            <span className="d-inline-block btn-text">
                              Edit
                            </span>
                          </div>
                          <div className="d-flex flex-wrap justify-content-between align-items-center extra-info">
                            <div>
                              <div className="d-flex align-items-center">
                                <figure className="flex-shrink-0 mb-0">
                                  <img
                                    src="../../../icons/icon-calendar.svg"
                                    alt=""
                                  />
                                </figure>
                                <div>
                                  <p className="subtitle-2 mb-0">
                                    Date of Birth
                                  </p>
                                  <p className="body-2 mb-0">
                                    {moment(puppy.dob).format("DD MMM YYYY")}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <figure className="flex-shrink-0 mb-0">
                                  <img
                                    src={`../../../icons/icon-gender-${
                                      puppy.sex === "Male" ? "male" : "female"
                                    }.svg`}
                                    alt=""
                                  />
                                </figure>
                                <div>
                                  <p className="subtitle-2 mb-0">Sex</p>
                                  <p className="body-2 mb-0">{puppy.sex}</p>
                                </div>
                              </div>
                            </div>
                            <div>
                              <div className="d-flex align-items-center">
                                <figure className="flex-shrink-0 mb-0">
                                  <img
                                    src="../../../icons/icon-weight.svg"
                                    alt=""
                                  />
                                </figure>
                                <div>
                                  <p className="subtitle-2 mb-0">
                                    Last weight added
                                  </p>
                                  <p className="body-2 mb-0">
                                    {puppy.weights.weights.length !== 0
                                      ? filteredCurrentPuppy[0].weightKg.toFixed(
                                          2
                                        )
                                      : 0}{" "}
                                    kg
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="position-absolute bottom-0 pet-icon">
                            <figure className="mb-1">
                              <img
                                src={`/icon-${
                                  puppy.species === "dog" ? "puppy" : "kitten"
                                }.png`}
                                alt=""
                              />
                            </figure>
                          </div> */}
                          <div className="position-absolute bottom-0 pet-icon">
                            <figure className="mb-1">
                              {puppy.species === "dog" &&
                              puppy.breed !== null ? (
                                <img
                                  src={`./puppy-icons/${puppy.breed
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}.svg`}
                                  onError={e => {
                                    e.target.src = "./puppy-icons/puppy.svg"
                                  }}
                                  alt={`${puppy.breed}`}
                                />
                              ) : puppy.species === "dog" &&
                                puppy.breed === null ? (
                                <img src={`./puppy-icons/puppy.svg`} />
                              ) : (
                                <img
                                  src={`./puppy-icons/kitten.svg`}
                                  alt={`${puppy.breed}`}
                                />
                              )}
                            </figure>
                          </div>
                          <div
                            className="d-flex justify-content-end d-lg-none body-2"
                            onClick={() => {
                              tabInitialKeyContext.setState("growthChart")
                              growthChartSnackbarContext.setState({
                                classes: "alert-light icon-info",
                                confidenceMessage: `${
                                  currentPetDataContext.state.weights.weights
                                    .length !== 0
                                    ? currentPetDataContext.state.weights
                                        .recommendation.confidenceMessage.text
                                    : ""
                                }`,
                                growthGuidance: `${
                                  currentPetDataContext.state.weights.weights
                                    .length !== 0
                                    ? currentPetDataContext.state.weights
                                        .recommendation.growthGuidance.text
                                    : ""
                                }`,
                              })
                            }}
                          >
                            View growth chart
                          </div>
                        </div>
                      </>
                    )
                  }}
                </SwiperSlide>
              </>
            )
          })}
          {searchPets.searchTerm === "" && searchPets.petSpecies === "" && (
            <SwiperSlide key="add-new-puppy">
              <div
                className="puppy-card__block add-new-puppy"
                onClick={() => modalContext.dispatch("showAddPuppyModal")}
              >
                <p className="subtitle-2 text-white text-center mb-0 d-none d-lg-block">
                  Add New Pet
                </p>
                <div className="d-flex align-items-center d-lg-block">
                  <figure className="flex-shrink-0 mb-0 me-3 me-lg-0">
                    <img
                      className="img-fluid"
                      src="../../../icons/icon-add.png"
                      alt=""
                    />
                  </figure>
                  <h6 className="text-white mb-0 d-lg-none">Add New Pet</h6>
                </div>
              </div>
            </SwiperSlide>
          )}
          {puppies.length !== 0 && filteredPuppies.length === 0 && (
            <>
              <NoPetsFound searchPets={searchPets} />
            </>
          )}
          {puppies.length === 0 && searchPets.searchTerm !== "" && (
            <NoPetsFound searchPets={searchPets} />
          )}
        </Swiper>
        {/* )} */}

        {filteredPuppies.length !== 0
          ? !skeletonLoadingContext.skeletonLoading && (
              <>
                <div
                  className={`swiper-button-prev d-none d-lg-block ${
                    swiper
                      ? swiper.isBeginning && "swiper-button-disabled"
                      : null
                  }`}
                  onClick={() => swiper.slidePrev()}
                ></div>
                <div
                  className={`swiper-button-next d-none d-lg-block ${
                    swiper ? swiper.isEnd && "swiper-button-disabled" : null
                  }`}
                  onClick={() => swiper.slideNext()}
                ></div>
              </>
            )
          : null}
      </div>
    </>
  )
}

export default React.memo(PuppyListCarousel)
