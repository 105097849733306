import React, { useState, useContext } from "react";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import { isEqual } from "lodash";

import {
  IsNewUserRequestContext,
  ModalContext,
  LoginProfileDataContext,
  SnackbarContext,
  PetDataContext,
  EmailRequestContext,
  ResetPasswordContext,
} from "../../app";

function UserProfile(props) {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const modalContext = useContext(ModalContext);
  const isNewUserRequestContext = useContext(IsNewUserRequestContext);
  const emailRequestContext = useContext(EmailRequestContext);
  const resetPasswordContext = useContext(ResetPasswordContext);
  const loginProfileDataContext = useContext(LoginProfileDataContext);
  const petDataContext = useContext(PetDataContext);
  const snackbarContext = useContext(SnackbarContext);
  const showDataUsageCaption = false;

  let validationSchema;
  if (props.updateUserProfile) {
    validationSchema = Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      emailAddress: Yup.string().email().required("Required"),
      userPassword: Yup.string(),
    });
  } else {
    validationSchema = Yup.object({
      firstName: Yup.string().required("Required"),
      lastName: Yup.string().required("Required"),
      emailAddress: Yup.string().email().required("Required"),
      password: Yup.string().required("Required"),
      userPassword: Yup.string(),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password doesn't match")
        .required("Required"),
    });
  }

  const handleSubmit = (values) => {
    // props.setChangeEmail(values.emailAddress);
    if (props.updateUserProfile) {
      if (isEqual(props.userInitialValues, props.data)) {
        modalContext.dispatch("hideUpdateUserProfileModal");
        snackbarContext.setState({
          classes: "alert-danger icon-failed",
          content: "There are no changes to submit",
        });
      } else {
        props.setChangeEmail(values.emailAddress);
        props.makeUpdateOwnerRequest(values);
      }
    } else {
      props.next(values, true, false);
    }
  };

  const toggleConfirmPassword = () => {
    setConfirmPasswordShown(!confirmPasswordShown);
  };

  return (
    <>
      {/* {props.updateUserProfile && <p className="caption mb-4">User data</p>} */}
      {!props.updateUserProfile && !props.resetPassBtnClicked && (
        <>
          <div className="alert alert-success icon icon-info" role="alert">
            Add your details to complete the registration
          </div>
          <div className="message">
            <p className="caption text-center mb-0">
              Please, add your personal information to save your pet's data or{" "}
              <span
                className="text-mars-red cursor-pointer ms-1"
                onClick={() => {
                  petDataContext.setState(props.data);
                  modalContext.dispatch("hideSignupModal");
                  modalContext.dispatch("showLoginModal");
                }}
              >
                log in
              </span>
            </p>
          </div>
        </>
      )}

      {!props.resetPassBtnClicked && (
        <p className="caption text-mars-blue">
          All fields marked with an asterisk (
          <span className="text-mars-red">*</span>) are required
        </p>
      )}
      <Formik
        initialValues={props.data}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formik) => {
          props.setData(formik.values);

          return (
            <Form id="myform" autoComplete="off">
              <div
                className={`form-input-hldr ${
                  formik.touched.firstName && formik.errors.firstName
                    ? "input-error"
                    : null
                }`}
              >
                <label htmlFor="firstName" className="form-label">
                  First Name*{" "}
                </label>

                <Field
                  type="text"
                  className="form-control"
                  id="firstName"
                  name="firstName"
                  placeholder="First Name"
                />
              </div>
              <div
                className={`form-input-hldr ${
                  formik.touched.lastName && formik.errors.lastName
                    ? "input-error"
                    : null
                }`}
              >
                <label htmlFor="lastName" className="form-label">
                  Last Name*{" "}
                </label>

                <Field
                  type="text"
                  className="form-control"
                  id="lastName"
                  name="lastName"
                  placeholder="Last Name"
                />
              </div>
              <div
                className={`form-input-hldr ${
                  (formik.touched.emailAddress && formik.errors.emailAddress) ||
                  isNewUserRequestContext.state.userAlreadyExists || props.emailError
                    ? "input-error"
                    : null
                }`}
              >
                <label htmlFor="emailAddress" className="form-label">
                  Email address*
                </label>

                <Field name="emailAddress">
                  {({ form, field }) => {
                    const { setFieldValue, setFieldTouched } = form;
                    const { value } = field;
                    return (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          id="emailAddress"
                          placeholder="Email address"
                          value={value}
                          onChange={(e) => {
                            setFieldValue("emailAddress", e.target.value);
                            props.setEmailError("");
                            isNewUserRequestContext.dispatch({
                              type: "USER_EXISTS_RESET",
                            });
                          }}
                          onBlur={() => {
                            setFieldTouched("emailAddress");
                          }}
                        />
                      </>
                    );
                  }}
                </Field>
                {isNewUserRequestContext.state.userAlreadyExists !== "" && (
                  <p className="caption text-mars-red">
                    {/* {isNewUserRequestContext.state.userAlreadyExists} */}
                    Account already exists with this email address. Please login
                    or reset your password{" "}
                    <span
                      className="text-mars-red cursor-pointer text-decoration-underline"
                      onClick={() => {
                        modalContext.dispatch("hideSignupModal");
                        modalContext.dispatch("showLoginModal");
                      }}
                    >
                      here
                    </span>
                  </p>
                )}
                {props.emailError && (
                  <p className="caption text-mars-red">{props.emailError}</p>
                )}
              </div>

              {!props.updateUserProfile && (
                <div className="form-input-group-hldr">
                  <div
                    className={`form-input-hldr ${
                      (formik.touched.password && formik.errors.password) ||
                      emailRequestContext.state.passwordPatternError !== ""
                        ? "input-error"
                        : null
                    }`}
                  >
                    <label htmlFor="password" className="form-label">
                      Password*{" "}
                    </label>
                    <div className="toggle-password-hldr">
                      <Field name="password">
                        {({ form, field }) => {
                          const { setFieldValue, setFieldTouched } = form;
                          const { value } = field;
                          return (
                            <>
                              <input
                                type={passwordShown ? "text" : "password"}
                                className="form-control"
                                id="password"
                                placeholder="Password"
                                value={value}
                                onChange={(e) => {
                                  setFieldValue("password", e.target.value);
                                  emailRequestContext.dispatch({
                                    type: "PASSWORD_ERROR_RESET",
                                  });
                                }}
                                onBlur={() => {
                                  setFieldTouched("password");
                                }}
                              />
                              <span
                                className={`toggle-password ${
                                  passwordShown ? "on" : ""
                                }`}
                                onClick={() => setPasswordShown(!passwordShown)}
                              ></span>
                            </>
                          );
                        }}
                      </Field>
                    </div>
                    {emailRequestContext.state.passwordPatternError !== "" && (
                      <p className="caption text-mars-red">
                        {emailRequestContext.state.passwordPatternError} <br />
                        Please fulfill the below mentioned criterias.
                      </p>
                    )}
                  </div>
                  <div className="instructions">
                    <p className="caption">
                      Password requirements:
                      <span className="d-block">
                        {" "}
                        &#8226; &nbsp; At least 8 characters{" "}
                      </span>
                      <span className="d-block">
                        {" "}
                        &#8226; &nbsp; At least one uppercase and one lowercase
                        letter
                      </span>
                      <span className="d-block">
                        {" "}
                        &#8226; &nbsp; At least one number{" "}
                      </span>
                      <span className="d-block">
                        {" "}
                        &#8226; &nbsp; May not contain your first or last name
                      </span>
                    </p>
                    <p className="caption mb-0">
                      If changing or resetting your password, your new password
                      should be different to your last 3 passwords
                    </p>
                  </div>
                  <div
                    className={`form-input-hldr ${
                      (formik.touched.confirmPassword &&
                        formik.errors.confirmPassword) ||
                      emailRequestContext.state.passwordPatternError !== ""
                        ? "input-error"
                        : null
                    }`}
                  >
                    <label htmlFor="confirmPassword" className="form-label">
                      Confirm password*
                    </label>
                    <div className="toggle-password-hldr">
                      <Field
                        type={confirmPasswordShown ? "text" : "password"}
                        className="form-control"
                        id="confirmPassword"
                        name="confirmPassword"
                        placeholder="Confirm password"
                      />
                      <span
                        className={`toggle-password ${
                          confirmPasswordShown ? "on" : ""
                        }`}
                        onClick={toggleConfirmPassword}
                      ></span>
                    </div>
                    {formik.errors.confirmPassword &&
                    formik.values.confirmPassword !== "" &&
                    formik.values.password !== formik.values.confirmPassword ? (
                      <p className="caption text-mars-red">
                        Password doesn't match
                      </p>
                    ) : null}
                  </div>
                  {showDataUsageCaption && (
                    <p className="caption text-mars-blue star">
                      From time to time, we may use your data for research to
                      enhance our product and service offerings. You can find
                      out how Mars Petcare and its affiliates collects and
                      processes your data, contact us with privacy questions,
                      and exercise your personal data rights via the{" "}
                      <a
                        className=" text-decoration-none text-mars-red"
                        href="https://www.mars.com/privacy"
                        target="_blank"
                      >
                        Mars Privacy Statement
                      </a>
                    </p>
                  )}
                </div>
              )}
              {props.updateUserProfile && (
                <>
                  <div className="form-input-hldr">
                    <p className="caption">Weight Unit*</p>
                    <div className="btn-group">
                      <Field
                        id="weightUnitProfileKg"
                        className="btn-check"
                        type="radio"
                        name="weightUnitProfile"
                        value="kg"
                      />
                      <label className="btn" htmlFor="weightUnitProfileKg">
                        kg
                      </label>

                      <Field
                        id="weightUnitProfileLbs"
                        className="btn-check"
                        type="radio"
                        name="weightUnitProfile"
                        value="lbs"
                      />
                      <label className="btn" htmlFor="weightUnitProfileLbs">
                        lbs
                      </label>
                    </div>
                  </div>

                  <div className="form-input-hldr input-disabled">
                    <label htmlFor="userPassword" className="form-label">
                      Password*{" "}
                    </label>
                    <div className="toggle-password-hldr">
                      <Field
                        type="password"
                        className="form-control"
                        id="userPassword"
                        name="userPassword"
                        placeholder="Password"
                        disabled={props.updateUserProfile}
                      />
                      <span className="toggle-password"></span>
                    </div>
                  </div>
                  <div>
                    <span
                      className="d-inline-block btn-text"
                      onClick={() => {
                        props.setUpdateUserProfile(false);
                        props.setUpdateEmail(false);
                        props.setResetPassBtnClicked(true);
                        props.setEmailUpdated(false);
                      }}
                    >
                      Reset password
                    </span>
                  </div>
                  <div className="d-inline-flex align-items-center btn-text-icon">
                    <figure className="flex-shrink-0 mb-0">
                      <img
                        className="img-fluid"
                        src="../../../icons/icon-trash.svg"
                        alt=""
                      />
                    </figure>
                    <span
                      className="d-inline-block btn-text"
                      onClick={() => {
                        modalContext.dispatch("hideUpdateUserProfileModal");
                        modalContext.dispatch("showRemoveUserProfileModal");
                        // alert("hello")
                      }}
                    >
                      Remove user profile
                    </span>
                  </div>
                </>
              )}
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

export default UserProfile;
