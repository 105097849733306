import React, { useContext } from "react"
import Dropdown from "react-bootstrap/Dropdown"

import {
  ModalContext,
  LoginProfileDataContext,
  IsLoginContext,
  SnackbarContext,
} from "../app"

const ProfileDropdown = () => {
  const modalContext = useContext(ModalContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const isLoginContext = useContext(IsLoginContext)
  const snackbarContext = useContext(SnackbarContext)
  const logOutHandler = () => {
    isLoginContext.setIsLogin(false)
    loginProfileDataContext.setState({})
    snackbarContext.setState({
      classes: "",
      content: "",
    })
    localStorage.removeItem("token")
  }
  return (
    <>
      <Dropdown className="login-profile-container" align={{ lg: "end" }}>
        <Dropdown.Toggle as="div" className="d-flex login-profile-info">
          <div className="login-profile-picture d-flex justify-content-center align-items-center">
            <figure className="mb-0">
              <img
                className="img-fluid d-block"
                src="../../../icons/icon-profile.svg"
                alt=""
              />
            </figure>
          </div>
          <div>
            <p className="subtitle-2 mb-0">
              {loginProfileDataContext.state?.owner?.first_name}
            </p>
            <p className="caption mb-0">
              {loginProfileDataContext.state?.owner?.email}
            </p>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu className="login-profile-dropdown">
          <p className="caption mb-0">You are logged in as</p>
          <p className="subtitle-2 mb-0">
            {loginProfileDataContext.state?.owner?.email}
          </p>
          <hr className="divider" />
          <span
            className="btn-text"
            onClick={() => modalContext.dispatch("showUpdateUserProfileModal")}
          >
            Manage profile
          </span>
          <span className="btn-text" onClick={() => logOutHandler()}>
            Log out
          </span>
        </Dropdown.Menu>
      </Dropdown>
    </>
  )
}

export default ProfileDropdown
