import React, { useContext, useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import axios from "axios";

import {
  LoadingContext,
  EmailRequestContext,
  ResetPasswordContext,
} from "../../app";

const EmailVerification = (props) => {
  const resetPasswordContext = useContext(ResetPasswordContext);
  const emailRequestContext = useContext(EmailRequestContext);
  const loadingContext = useContext(LoadingContext);

  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  let validationSchema, initialValues;
  if (resetPasswordContext.state) {
    initialValues = {
      newPassword: "",
      confirmNewPassword: "",
    };

    validationSchema = Yup.object({
      newPassword: Yup.string().required("Required"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Password doesn't match")
        .required("Required"),
    });
  }

  // const makeResendEmailRequest = () => {
  //   loadingContext.setLoading(true);

  //   const dataToSend = {
  //     email: props.data.emailAddress,
  //     password: props.data.password,
  //     first_name: props.data.firstName,
  //     last_name: props.data.lastName,
  //   };

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/register-owner`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   };
  //   // Send Data to API
  //   axios(config)
  //     .then((response) => {
  //       // console.log(response)
  //       loadingContext.setLoading(false);
  //       setSignupEmailSentMsg("resend msg");
  //       emailRequestContext.dispatch({
  //         type: "EMAIL_SENT_SUCCESS",
  //         authId: response.data.authId,
  //         ownerEnterCookie: response.data.owner_enter_cookie,
  //       });
  //     })
  //     .catch((error) => {
  //       // console.log(error)
  //       loadingContext.setLoading(false);
  //     });
  // };

  const handleSubmit = (values) => {
    setTimeout(() => {
      props.setResetPassBtnClicked(false);
    }, 2000);
    if (props.forgotPassword) {
      props.makeResetPasswordRequest(values);
    } else {
      props.next(values, false, true);
    }
  };

  // console.log(resetPasswordContext.state, "resetPasswordContext.state")
  // console.log(props.updateEmail, "props.updateEmail")
  // console.log(props.resetPasswordReq, "props.resetPasswordReq")

  return (
    <>
      {(props.signupForm || props.forgotPassword) &&
        !props.updateEmail &&
        !props.resetPassBtnClicked &&
        !props.passwordError && (
          <>
            <p className="body-2 mb-0">{props.title}</p>
            <div className="otp-container">
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../../banner-dogs-otp.png"
                  alt=""
                />
              </figure>
              {props.signupForm && (
                <div className="email-Verification-hldr text-center mt-4">
                  {props.signupEmailSentMsg === "default msg" && (
                    <p className="mt-3">
                      <b className="email-verification-bold-msg">
                        Please click on the link
                      </b>{" "}
                      in the email we just sent you to confirm your email
                      address.
                    </p>
                  )}
                  {props.signupEmailSentMsg === "resend msg" && (
                    <p>
                      Oops – having trouble receiving the verification link?
                      Please check your spam folder. If it is not here, we may
                      be having a problem verifying this email domain. Please
                      try a different email address.
                    </p>
                  )}
                </div>
              )}
              {props.forgotPassword && !props.resendEmail && (
                <p className="mt-3 text-center">
                  A Password Reset link has been sent to your email.{" "}
                  <b className="email-verification-bold-msg">
                    Please click on the link
                  </b>{" "}
                  to complete the Password Reset process.
                </p>
              )}
              {props.resendEmail && (
                <p className="mt-3 text-center">
                  <b className="email-verification-bold-msg">
                    Please click on the link
                  </b>{" "}
                  in the email we just sent you to confirm your email address.
                </p>
              )}
              <div className="text-center">
                <button
                  className="btn btn-transparent btn-text"
                  onClick={() => {
                    if (props.forgotPassword && !props.resendEmail) {
                      props.makeForgotPasswordRequest(props.emailAddress);
                    } else if (props.signupForm) {
                      props.makeResendEmailRequest(props.emailAddress);
                    } else if (props.forgotPassword && props.resendEmail) {
                      props.makeResendEmailRequest(props.email);
                    }
                  }}
                >
                  Resend Email
                </button>
              </div>
            </div>
          </>
        )}

      {props.updateEmail &&
        resetPasswordContext.state &&
        !props.resetPassBtnClicked &&
        !props.passwordError && (
          <>
            <p className="body-2 mb-0">{props.title}</p>
            <div className="otp-container">
              <figure className="mb-0">
                <img
                  className="img-fluid"
                  src="../../../banner-dogs-otp.png"
                  alt=""
                />
              </figure>
              {props.updateEmail && (
                <div className="email-Verification-hldr text-center mt-4">
                  <p className="mt-3">
                    <b className="email-verification-bold-msg">
                      Please click on the link
                    </b>{" "}
                    in the email we just sent you to confirm your email address.
                  </p>
                  <button
                    className="btn btn-transparent btn-text"
                    onClick={() => {
                      props.makeResendEmailRequest(props.changeEmail);
                    }}
                  >
                    Resend Email
                  </button>
                </div>
              )}
            </div>
          </>
        )}

      {!props.updateEmail &&
        resetPasswordContext.state &&
        (props.resetPassBtnClicked || props.passwordError) && (
          <Formik
            initialValues={
              resetPasswordContext.state ? initialValues : props.data
            }
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {(formik) => {
              return (
                <Form id="myform" autoComplete="off">
                  <div className="form-input-group-hldr">
                    <div
                      className={`form-input-hldr ${
                        (formik.touched.newPassword &&
                          formik.errors.newPassword) ||
                        props.passwordNotChanged
                          ? "input-error"
                          : null
                      }`}
                    >
                      <label htmlFor="newPassword" className="form-label">
                        New Password*
                      </label>
                      <div className="toggle-password-hldr">
                        <Field name="newPassword">
                          {({ form, field }) => {
                            const { setFieldValue, setFieldTouched } = form;
                            const { value } = field;
                            return (
                              <>
                                <input
                                  type={passwordShown ? "text" : "password"}
                                  className="form-control"
                                  id="newPassword"
                                  placeholder="New Password"
                                  value={value}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "newPassword",
                                      e.target.value
                                    );
                                    props.setPasswordNotChanged(false);
                                    // props.passwordError(true);
                                    // props.resetPassBtnClicked(true);
                                    emailRequestContext.dispatch({
                                      type: "PASSWORD_ERROR_RESET",
                                    });
                                  }}
                                  onBlur={() => {
                                    setFieldTouched("newPassword");
                                  }}
                                />
                                <span
                                  className={`toggle-password ${
                                    passwordShown ? "on" : ""
                                  }`}
                                  onClick={() =>
                                    setPasswordShown(!passwordShown)
                                  }
                                ></span>
                              </>
                            );
                          }}
                        </Field>
                      </div>
                      {emailRequestContext.state.passwordPatternError !==
                        "" && (
                        <p className="caption text-mars-red">
                          {emailRequestContext.state.passwordPatternError}{" "}
                          <br />
                          Please fulfill the below mentioned criterias.
                        </p>
                      )}
                    </div>

                    <div className="instructions">
                      <p className="caption">
                        Password requirements:
                        <span className="d-block">
                          {" "}
                          &#8226; &nbsp; At least 8 characters{" "}
                        </span>
                        <span className="d-block">
                          {" "}
                          &#8226; &nbsp; At least one uppercase and one
                          lowercase letter
                        </span>
                        <span className="d-block">
                          {" "}
                          &#8226; &nbsp; At least one number{" "}
                        </span>
                        <span className="d-block">
                          {" "}
                          &#8226; &nbsp; May not contain your first or last name
                        </span>
                      </p>
                      <p className="caption mb-0">
                        If changing or resetting your password, your new
                        password should be different to your last 3 passwords
                      </p>
                    </div>
                    <div
                      className={`form-input-hldr ${
                        (formik.touched.confirmNewPassword &&
                          formik.errors.confirmNewPassword) ||
                        props.passwordNotChanged
                          ? "input-error"
                          : null
                      }`}
                    >
                      <label
                        htmlFor="confirmNewPassword"
                        className="form-label"
                      >
                        Confirm new password*
                      </label>
                      <div className="toggle-password-hldr">
                        <Field name="confirmNewPassword">
                          {({ form, field }) => {
                            const { setFieldValue, setFieldTouched } = form;
                            const { value } = field;
                            return (
                              <>
                                <input
                                  type={
                                    confirmPasswordShown ? "text" : "password"
                                  }
                                  className="form-control"
                                  id="confirmNewPassword"
                                  placeholder="Confirm new Password"
                                  value={value}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "confirmNewPassword",
                                      e.target.value
                                    );
                                    props.setPasswordNotChanged(false);
                                    // props.passwordError(true);
                                    // props.resetPassBtnClicked(true);
                                    emailRequestContext.dispatch({
                                      type: "PASSWORD_ERROR_RESET",
                                    });
                                  }}
                                  onBlur={() => {
                                    setFieldTouched("confirmNewPassword");
                                  }}
                                />
                                <span
                                  className={`toggle-password ${
                                    confirmPasswordShown ? "on" : ""
                                  }`}
                                  onClick={() =>
                                    setConfirmPasswordShown(
                                      !confirmPasswordShown
                                    )
                                  }
                                ></span>
                              </>
                            );
                          }}
                        </Field>
                      </div>
                      {formik.errors.confirmNewPassword &&
                      formik.values.confirmNewPassword !== "" &&
                      formik.values.newPassword !==
                        formik.values.confirmNewPassword ? (
                        <p className="caption text-mars-red">
                          Password doesn't match
                        </p>
                      ) : null}
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        )}
    </>
  );
};

export default EmailVerification;
