import React, { useState, useEffect, useReducer, useCallback } from "react";

import axios from "axios";

import PageHeading from "./page-heading/page-heading";
import Welcome from "./templates/welcome/welcome";
import Homepage from "./templates/homepage/homepage";
import FormLoadingIndicator from "./loading-indicators/form-loading-indicator";

const initialIsNewUserRequestValues = {
  isEmailAlreadyVerified: false,
  userAlreadyExists: "",
};
const isNewUserRequestReducer = (state, action) => {
  switch (action.type) {
    case "USER_VERIFIED_NOT_SUBMITTED":
      return { ...state, isEmailAlreadyVerified: true };
    case "USER_EXISTS":
      return { ...state, userAlreadyExists: action.message };
    case "USER_EXISTS_RESET":
      return initialIsNewUserRequestValues;
    default:
      return state;
  }
};

const initialEmailRequestValues = {
  passwordPatternError: "",
  authId: "",
  ownerEnterCookie: "",
};

const emailRequestReducer = (state, action) => {
  switch (action.type) {
    case "PASSWORD_FAILED":
      return {
        ...state,
        passwordPatternError: action.passwordErrorText,
      };
    case "EMAIL_SENT_SUCCESS":
      return {
        ...state,
        passwordPatternError: "",
        authId: action.authId,
        ownerEnterCookie: action.ownerEnterCookie,
      };
    case "PASSWORD_ERROR_RESET":
      return { ...state, passwordPatternError: "" };
    default:
      return state;
  }
};

const initialModalState = {
  modalSignupShow: false,
  modalLoginShow: false,
  modalUpdateUserProfileShow: false,
  modalUpdatePetsProfileShow: false,
  modalAddPuppyShow: false,
  modalRemoveUserProfileShow: false,
  modalUnsavedPetsShow: false,
};
const modalReducer = (state, action) => {
  switch (action) {
    case "showSignupModal":
      return { modalSignupShow: true };
    case "hideSignupModal":
      return { modalSignupShow: false };
    case "showLoginModal":
      return { modalLoginShow: true };
    case "hideLoginModal":
      return { modalLoginShow: false };
    case "showUpdateUserProfileModal":
      return { modalUpdateUserProfileShow: true };
    case "hideUpdateUserProfileModal":
      return { modalUpdateUserProfileShow: false };
    case "showUpdatePetsProfileModal":
      return { modalUpdatePetsProfileShow: true };
    case "hideUpdatePetsProfileModal":
      return { modalUpdatePetsProfileShow: false };
    case "showAddPuppyModal":
      return { modalAddPuppyShow: true };
    case "hideAddPuppyModal":
      return { modalAddPuppyShow: false };
    case "showRemoveUserProfileModal":
      return { modalRemoveUserProfileShow: true };
    case "hideRemoveUserProfileModal":
      return { modalRemoveUserProfileShow: false };

    case "showRemovePetsProfileModal":
      return { modalRemovePetsProfileShow: true };
    case "hideRemovePetsProfileModal":
      return { modalRemovePetsProfileShow: false };
    default:
      return state;
  }
};

export const PetBreedsContext = React.createContext();
export const IsLoginContext = React.createContext();
export const FetchDataContext = React.createContext();
export const IsNewUserRequestContext = React.createContext();
export const EmailRequestContext = React.createContext();
export const ResetPasswordContext = React.createContext();
export const ModalContext = React.createContext();
export const SnackbarContext = React.createContext();
export const AddedPetIdContext = React.createContext();

export const LoginProfileDataContext = React.createContext();
export const LoadingContext = React.createContext();
export const SkeletonLoadingContext = React.createContext();
export const TabInitialKeyContext = React.createContext();
export const TabRenderContext = React.createContext();
export const GrowthChartSnackbarContext = React.createContext();
export const PetDataContext = React.createContext();

function App() {
  const [petBreedOptions, setPetBreedOptions] = useState([]);
  const [isLogin, setIsLogin] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);
  const [loginProfileData, setLoginProfileData] = useState({});
  const [petData, setPetData] = useState({});
  const [loading, setLoading] = useState(true);
  const [addedPetId, setAddedPetId] = useState("");

  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [tabInitialKey, setTabInitialKey] = useState("myPets");
  const [tabRender, setTabRender] = useState(false);

  const [snackbar, setSnackbar] = useState({
    classes: "",
    content: "",
  });
  const [growthChartSnackbar, setGrowthChartSnackbar] = useState({
    classes: "",
    confidenceMessage: "",
    growthGuidance: "",
  });

  const [isNewUserRequestState, isNewUserRequestDispatch] = useReducer(
    isNewUserRequestReducer,
    initialIsNewUserRequestValues
  );
  const [emailRequestState, emailRequestDispatch] = useReducer(
    emailRequestReducer,
    initialEmailRequestValues
  );
  const [modalState, modalDispatch] = useReducer(
    modalReducer,
    initialModalState
  );

  const fetchDataRequest = useCallback(() => {
    if (!isLogin) {
      setLoading(true);
    } else {
      setSkeletonLoading(true);
    }

    if (tabRender) {
      setTabInitialKey("myPets");
    }

    axios
      .get(`${process.env.GATSBY_BASE_URL}/api/v1/fetch-data`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      })
      .then(function (response) {
        // console.log(response)
        if (!isLogin) {
          setLoading(false);
        } else {
          setSkeletonLoading(false);
        }
        if (response.data.owner && response.data.pets) {
          setLoginProfileData(response.data);
          setIsLogin(true);
          if (!loading && tabRender) {
            setTimeout(() => {
              setTabInitialKey("growthChart");
            }, 500);
          }
        } else {
          setIsLogin(false);
        }
      })
      .catch(function (error) {
        // console.log(error)
        if (!isLogin) {
          setLoading(false);
        } else {
          setSkeletonLoading(false);
        }
        setIsLogin(false);
      });
  });

  useEffect(() => {
    axios
      .get(`${process.env.GATSBY_BASE_URL}/api/v1/breeds`)
      .then(function (response) {
        setPetBreedOptions(response.data);
      })
      .catch(function (error) {
        // console.log(error)
      });
  }, []);

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchDataRequest();
    } else {
      setLoading(false);
      setIsLogin(false);
    }
  }, []);

  // function scrollFun() {
  //   document.documentElement.scrollIntoView()
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     let height
  //     if (height !== document.getElementById("iframe-height").offsetHeight) {
  //       height = document.getElementById("iframe-height").offsetHeight
  //       window.parent.postMessage(
  //         {
  //           frameHeight: height,
  //           scrollFunction: scrollFun(),
  //         },
  //         "*"
  //       )
  //     }
  //   }, 1000)
  // }, [isLogin])
  return (
    <>
      {loading && <FormLoadingIndicator />}
      <PetBreedsContext.Provider value={petBreedOptions}>
        <IsLoginContext.Provider value={{ isLogin, setIsLogin }}>
          <PetDataContext.Provider
            value={{ state: petData, setState: setPetData }}
          >
            <AddedPetIdContext.Provider
              value={{ state: addedPetId, setState: setAddedPetId }}
            >
              <FetchDataContext.Provider value={fetchDataRequest}>
                <IsNewUserRequestContext.Provider
                  value={{
                    state: isNewUserRequestState,
                    dispatch: isNewUserRequestDispatch,
                  }}
                >
                  <EmailRequestContext.Provider
                    value={{
                      state: emailRequestState,
                      dispatch: emailRequestDispatch,
                    }}
                  >
                    <ResetPasswordContext.Provider
                      value={{
                        state: resetPassword,
                        setState: setResetPassword,
                      }}
                    >
                      <ModalContext.Provider
                        value={{ state: modalState, dispatch: modalDispatch }}
                      >
                        <LoginProfileDataContext.Provider
                          value={{
                            state: loginProfileData,
                            setState: setLoginProfileData,
                          }}
                        >
                          <SnackbarContext.Provider
                            value={{ state: snackbar, setState: setSnackbar }}
                          >
                            <GrowthChartSnackbarContext.Provider
                              value={{
                                state: growthChartSnackbar,
                                setState: setGrowthChartSnackbar,
                              }}
                            >
                              <LoadingContext.Provider
                                value={{ loading, setLoading }}
                              >
                                <SkeletonLoadingContext.Provider
                                  value={{
                                    skeletonLoading,
                                    setSkeletonLoading,
                                  }}
                                >
                                  <TabInitialKeyContext.Provider
                                    value={{
                                      state: tabInitialKey,
                                      setState: setTabInitialKey,
                                    }}
                                  >
                                    <TabRenderContext.Provider
                                      value={{
                                        state: tabRender,
                                        setState: setTabRender,
                                      }}
                                    >
                                      <div
                                        className={`${
                                          loading && !isLogin ? "d-none" : ""
                                        }`}
                                      >
                                        <div id="iframe-height">
                                          <PageHeading />
                                          {isLogin ? <Homepage /> : <Welcome />}
                                        </div>
                                      </div>
                                    </TabRenderContext.Provider>
                                  </TabInitialKeyContext.Provider>
                                </SkeletonLoadingContext.Provider>
                              </LoadingContext.Provider>
                            </GrowthChartSnackbarContext.Provider>
                          </SnackbarContext.Provider>
                        </LoginProfileDataContext.Provider>
                      </ModalContext.Provider>
                    </ResetPasswordContext.Provider>
                  </EmailRequestContext.Provider>
                </IsNewUserRequestContext.Provider>
              </FetchDataContext.Provider>
            </AddedPetIdContext.Provider>
          </PetDataContext.Provider>
        </IsLoginContext.Provider>
      </PetBreedsContext.Provider>
    </>
  );
}

export default React.memo(App);
