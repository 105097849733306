import React, { useContext } from "react"
import moment from "moment"
import axios from "axios"

import {
  SkeletonLoadingContext,
  LoadingContext,
  LoginProfileDataContext,
  FetchDataContext,
  SnackbarContext,
} from "../../app"

import {
  CurrentPetDataContext,
  UndoCloseContext,
  UndoContext,
} from "../../templates/homepage/homepage"

function Undo({
  addedWeight,
  setAddedWeight,
  addedNeutering,
  setAddedNeutering,
  addedNeuteringValue,
  addedWeightValues,
  weightUnitLocal,
}) {
  const skeletonLoadingContext = useContext(SkeletonLoadingContext)
  const loadingContext = useContext(LoadingContext)
  const fetchDataContext = useContext(FetchDataContext)
  const loginProfileDataContext = useContext(LoginProfileDataContext)
  const snackbarContext = useContext(SnackbarContext)
  const currentPetDataContext = useContext(CurrentPetDataContext)
  const undoCloseContext = useContext(UndoCloseContext)
  const undoContext = useContext(UndoContext)

  let newWeightTimeout
  if (!skeletonLoadingContext.skeletonLoading) {
    newWeightTimeout = setTimeout(() => resetValues(), 3000)
  }

  const resetValues = () => {
    clearTimeout(newWeightTimeout)
    undoCloseContext.setState(false)
    undoContext.setState(false)
    setAddedWeight({
      weight: "",
      weeks: "",
      confidenceMessage: { type: "", text: "" },
    })
    setAddedNeutering("")
  }
  const handleUndo = () => {
    if (addedWeight.weight !== "") {
      undoAddWeightRequest()
    } else if (addedNeutering !== "") {
      undoNeuteringRequest()
    }
  }

  // console.log(addedWeight)

  const undoAddWeightRequest = () => {
    loadingContext.setLoading(true)
    resetValues()
    const dataToSend = {
      petID: addedWeightValues.petID,
      weight_kg: addedWeightValues.weight_kg,
      date: moment(addedWeightValues.date).format("YYYY-MM-DD"),
    }
    var config = {
      method: "delete",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/delete-weight?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)

        fetchDataContext()
        snackbarContext.setState({
          classes: "alert-success icon-success",
          content: "Undo added weight happened successfully.",
        })
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
      })
  }

  const undoNeuteringRequest = () => {
    // console.log("Undo Neutering Date")

    loadingContext.setLoading(true)
    resetValues()
    const dataToSend = {
      neuter_date:
        addedNeuteringValue.prev_values.neuter_date === null
          ? null
          : moment(addedNeuteringValue.prev_values.neuter_date).format(
              "YYYY-MM-DD"
            ),
      prev_values: {
        neuter_date: moment(addedNeuteringValue.neuter_date).format(
          "YYYY-MM-DD"
        ),
      },
    }

    var config = {
      method: "patch",
      url: `${process.env.GATSBY_BASE_URL}/api/v1/update-pet?_queryFilter1=mail eq ${loginProfileDataContext.state.owner.email}&_queryFilter2=_id eq ${loginProfileDataContext.state.owner._id}&_queryFilter3=petID eq ${currentPetDataContext.state.petID}`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    }
    // Send Data to API
    axios(config)
      .then(response => {
        // console.log(response)
        loadingContext.setLoading(false)

        fetchDataContext()
        // props.onHide()
        // props.setUndo(true)
        // props.setAddedNeutering(
        //   moment(values.neuteringDate).format("D MMM YYYY")
        // )

        snackbarContext.setState({
          classes: "alert-success icon-success",
          content: "Undo neutering happened successfully.",
        })
      })
      .catch(error => {
        // console.log(error)
        loadingContext.setLoading(false)
        // props.onHide()
      })
  }
  let weightConverted

  if (weightUnitLocal === "lbs") {
    weightConverted = addedWeight.weight * 2.20462
  } else {
    weightConverted = addedWeight.weight
  }

  return (
    <>
      <div className={`modal-dialog-window undo`}>
        <div className="modal-content bg-image-none">
          <div className="modal-header">
            {addedWeight.weight !== "" && (
              <div>
                {/* <p className="body-2">{addedWeight.confidenceMessage.text}</p> */}
                {/* <p className="body-2 mb-0">
                  New weight of{" "}
                  <span className="subtitle-2">
                    {Number(weightConverted).toFixed(2)} {weightUnitLocal}{" "}
                  </span>{" "}
                  at{" "}
                  <span className="subtitle-2">{addedWeight.weeks} weeks</span>{" "}
                  has been added.
                </p> */}
                <p className="subtitle-2 mb-0">New weight!</p>
              </div>
            )}
            {addedNeutering !== "" && (
              <p className="body-2 mb-0">
                <span className="subtitle-2">Neutering</span> on{" "}
                {addedNeutering}
              </p>
            )}
            <button
              type="button"
              className="btn-close flex-shrink-0 d-lg-none"
              onClick={() => {
                resetValues()
              }}
            ></button>
          </div>
          {/* <div className="modal-body">
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-outline-mars-blue"
                onClick={handleUndo}
              >
                Undo
              </button>
            </div>
          </div> */}
        </div>
      </div>
    </>
  )
}

export default Undo
