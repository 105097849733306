import React, { useState, useEffect, useContext } from "react"
import useWindowWidth from "react-hook-use-window-width"

import ModalUpdateUserProfile from "../../modals/modal-update-user-profile"
import ModalUpdatePetsProfile from "../../modals/modal-update-pets-profile"
import ModalAddPuppy from "../../modals/modal-add-puppy"
import ModalRemoveUserProfile from "../../modals/modal-remove-user-profile"
import ModalRemovePetsProfile from "../../modals/modal-remove-pets-profile"

import HomepageDesktop from "./homepage-desktop"
import HomepageMobile from "./homepage-mobile"

import { ModalContext } from "../../app"

export const CurrentPetDataContext = React.createContext()
export const InitialSlideContext = React.createContext()
export const TabActiveKeyContext = React.createContext()

export const UndoContext = React.createContext()
export const UndoCloseContext = React.createContext()
export const ToggleConfidenceMessageSnackbarContext = React.createContext()
export const ToggleGrowthGuidanceSnackbarContext = React.createContext()

function Homepage() {
  const [searchPets, setSearchPets] = useState({
    searchTerm: "",
    petSpecies: "",
  })
  const [currentPetData, setCurrentPetData] = useState({
    weights: {
      weights: [],
    },
  })
  const [toggleConfidenceMessageSnackbar, setToggleConfidenceMessageSnackbar] =
    useState(false)
  const [toggleGrowthGuidanceSnackbar, setToggleGrowthGuidanceSnackbar] =
    useState(false)
  const [initialSlide, setInitialSlide] = useState(0)
  const [tabActiveKey, setTabActiveKey] = useState()

  const [undo, setUndo] = useState(false)
  const [undoClose, setUndoClose] = useState(false)

  const width = useWindowWidth()
  const modalContext = useContext(ModalContext)

  // function scrollFun() {
  //   document.documentElement.scrollIntoView()
  // }

  useEffect(() => {
    let height = 900
    if (document.querySelector("svg")) {
      setTimeout(() => {
        if (height !== document.getElementById("iframe-height")?.offsetHeight) {
          height = document.getElementById("iframe-height").offsetHeight
          window.parent.postMessage(
            {
              frameHeight: Number(`${height === 0 ? 900 : height}`),
              // scrollFunction: scrollFun(),
            },
            "*"
          )
        }
      }, 1000)
    }
  }, [])

  const currentYear = new Date().getFullYear()

  return (
    <>
      <CurrentPetDataContext.Provider
        value={{ state: currentPetData, setState: setCurrentPetData }}
      >
        <InitialSlideContext.Provider
          value={{ state: initialSlide, setState: setInitialSlide }}
        >
          <TabActiveKeyContext.Provider
            value={{ state: tabActiveKey, setState: setTabActiveKey }}
          >
            <ToggleConfidenceMessageSnackbarContext.Provider
              value={{
                state: toggleConfidenceMessageSnackbar,
                setState: setToggleConfidenceMessageSnackbar,
              }}
            >
              <ToggleGrowthGuidanceSnackbarContext.Provider
                value={{
                  state: toggleGrowthGuidanceSnackbar,
                  setState: setToggleGrowthGuidanceSnackbar,
                }}
              >
                <UndoContext.Provider
                  value={{ state: undo, setState: setUndo }}
                >
                  <UndoCloseContext.Provider
                    value={{ state: undoClose, setState: setUndoClose }}
                  >
                    {width >= 992 ? (
                      <>
                        <HomepageDesktop
                          searchPets={searchPets}
                          setSearchPets={setSearchPets}
                        />
                        <p className="body-1 text-mars-blue export-pdf-copyright export-pdf-element">
                          &copy;{currentYear} Mars or Affiliates. All Rights
                          Reserved
                        </p>
                      </>
                    ) : (
                      <HomepageMobile
                        searchPets={searchPets}
                        setSearchPets={setSearchPets}
                      />
                    )}

                    <ModalUpdateUserProfile
                      show={modalContext.state.modalUpdateUserProfileShow}
                      onHide={() =>
                        modalContext.dispatch("hideUpdateUserProfileModal")
                      }
                    />
                    <ModalUpdatePetsProfile
                      show={modalContext.state.modalUpdatePetsProfileShow}
                      onHide={() =>
                        modalContext.dispatch("hideUpdatePetsProfileModal")
                      }
                    />
                    <ModalAddPuppy
                      show={modalContext.state.modalAddPuppyShow}
                      onHide={() => modalContext.dispatch("hideAddPuppyModal")}
                    />
                    <ModalRemoveUserProfile
                      show={modalContext.state.modalRemoveUserProfileShow}
                      onHide={() =>
                        modalContext.dispatch("hideRemoveUserProfileModal")
                      }
                    />
                    <ModalRemovePetsProfile
                      show={modalContext.state.modalRemovePetsProfileShow}
                      onHide={() =>
                        modalContext.dispatch("hideRemovePetsProfileModal")
                      }
                    />
                  </UndoCloseContext.Provider>
                </UndoContext.Provider>
              </ToggleGrowthGuidanceSnackbarContext.Provider>
            </ToggleConfidenceMessageSnackbarContext.Provider>
          </TabActiveKeyContext.Provider>
        </InitialSlideContext.Provider>
      </CurrentPetDataContext.Provider>
    </>
  )
}

export default Homepage
