import React, { useState, useContext, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";

import UserProfile from "../forms/form-signup/form-user-profile";
// import OTPBox from "../forms/otp-box/otp-box"

import {
  LoadingContext,
  ModalContext,
  ResetPasswordContext,
  LoginProfileDataContext,
  SnackbarContext,
  FetchDataContext,
  IsLoginContext,
  TabRenderContext,
  EmailRequestContext,
} from "../app";
import { filter, initial } from "lodash";
import EmailVerification from "../forms/email-verification/email-verification";

function ModalUpdateUserProfile(props) {
  const isLoginContext = useContext(IsLoginContext);
  const loadingContext = useContext(LoadingContext);
  const resetPasswordContext = useContext(ResetPasswordContext);
  const loginProfileDataContext = useContext(LoginProfileDataContext);
  const modalContext = useContext(ModalContext);
  const snackbarContext = useContext(SnackbarContext);
  const fetchDataContext = useContext(FetchDataContext);
  const emailRequestContext = useContext(EmailRequestContext);

  const tabRenderContext = useContext(TabRenderContext);

  const initialValues = {
    firstName: loginProfileDataContext.state.owner.first_name,
    lastName: loginProfileDataContext.state.owner.last_name,
    emailAddress: loginProfileDataContext.state.owner.email,
    weightUnitProfile: loginProfileDataContext.state.owner.weight_unit,
    userPassword: "PasswordField",
  };

  const [data, setData] = useState(initialValues);
  const [updateUserProfile, setUpdateUserProfile] = useState(true);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [changeEmail, setChangeEmail] = useState("");
  const [resetPasswordReq, setResetPasswordReq] = useState(false);
  const [resetPassBtnClicked, setResetPassBtnClicked] = useState(false);
  const [passwordNotChanged, setPasswordNotChanged] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [emailUpdated, setEmailUpdated] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [forgotPasswordResponseData, setForgotPasswordResponseData] = useState(
    {}
  );

  const [loginForm, setLoginForm] = useState(true);
  const [credentialsError, setCredentialsError] = useState("");
  const [inCorrectEmail, setInCorrectEmail] = useState(false);

  const [verifyOTPResponse, setVerifyOTPResponse] = useState({});
  const [updateOwnerResponse, setUpdateOwnerResponse] = useState({});
  const [verifyOldOTPResponse, setVerifyOldOTPResponse] = useState({});
  const [oldOtp, setOldOtp] = useState(false);
  const [newOtp, setNewOtp] = useState(false);
  const [OTPError, setOTPError] = useState("");

  const removeTokenHandler = () => {
    localStorage.removeItem("token");
  };

  // const makeForgotPasswordRequest = emailAddress => {
  //   loadingContext.setLoading(true)
  //   setOTPError("")
  //   const dataToSend = {
  //     email: emailAddress,
  //   }

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v2/forgotpassword`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   }
  //   // Send Data to API
  //   axios(config)
  //     .then(response => {
  //       console.log(response)
  //       setUpdateEmail(false)
  //       loadingContext.setLoading(false)
  //       setUpdateUserProfile(false)
  //       setForgotPasswordResponseData(response.data)
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //       loadingContext.setLoading(false)
  //     })
  // }

  // const makeVerifyOtpRequest = (
  //   OTPValue,
  //   setOTPMatched,
  //   setOTPNotMatched,
  //   setOTPResendEnable,
  //   setOTPTimerStart,
  //   setOTPTimerDuration
  // ) => {
  //   loadingContext.setLoading(true)
  //   const dataToSend = {
  //     verifyOTPAuthID: forgotPasswordResponseData.verifyOTPAuthID,
  //     verifyOTPCookie: forgotPasswordResponseData.verifyOTPCookie,
  //     otp: OTPValue,
  //   }

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-otp`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   }
  //   // Send Data to API
  //   axios(config)
  //     .then(response => {
  //       // console.log(response)
  //       loadingContext.setLoading(false)
  //       if (response.data.message === "Login failure") {
  //         setOTPNotMatched(true)
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 2 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 2 more failure(s).")
  //         setOTPNotMatched(true)
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 1 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 1 more failure(s).")
  //         setOTPNotMatched(true)
  //       } else if (response.data.message === "User Locked Out.") {
  //         setOTPError(
  //           "Your account is temporarily locked. please retry in 5 minutes."
  //         )
  //         setOTPNotMatched(false)
  //         setOTPResendEnable(false)
  //         setOTPTimerStart(false)
  //         setOTPTimerDuration(30)
  //         removeTokenHandler()
  //       } else if (
  //         response.data.callbacks[0].type === "ValidatedCreatePasswordCallback"
  //       ) {
  //         setOTPError("")
  //         setOTPMatched(true)
  //         setOTPNotMatched(false)
  //         setOTPResendEnable(false)
  //         setOTPTimerStart(false)
  //         setOTPTimerDuration(30)
  //         setVerifyOTPResponse(response.data)
  //       } else {
  //         setOTPNotMatched(true)
  //       }
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //       loadingContext.setLoading(false)
  //       setOTPNotMatched(true)
  //     })
  // }

  const makeResetPasswordRequest = (formData) => {
    loadingContext.setLoading(true);
    const dataToSend = {
      _id: loginProfileDataContext.state.owner._id,
      password: formData.newPassword,
    };

    const config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/resetpassword`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (response.data.error === true) {
          setUpdateUserProfile(false);
          setUpdateEmail(false);
          setResetPassBtnClicked(false);
          emailRequestContext.dispatch({
            type: "PASSWORD_FAILED",
            passwordErrorText: response.data.message,
          });
          setPasswordNotChanged(true);
          setPasswordError(true);
        } else if (response.data.message === "Updated successfully") {
          setResetPasswordReq(response?.data?.message && true);
          setPasswordNotChanged(false);
          setPasswordError(false);
          modalContext.dispatch("hideUpdateUserProfileModal");
          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "Your password was changed successfully.",
          });
        } else {
          setPasswordNotChanged(true);
          // setPasswordError(true)
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  const makeResendEmailRequest = (emailAddress) => {
    loadingContext.setLoading(true);
    const dataToSend = {
      email: emailAddress,
    };

    const config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/verification-email`,
      headers: {
        "Content-Type": "application/json",
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        loadingContext.setLoading(false);
        if (response.data.message && response.data.reason) {
          setCredentialsError("");
          setInCorrectEmail(true);
        } else {
          setLoginForm(false);
          setForgotPasswordResponseData(response.data);
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
      });
  };

  // console.log(data);
  useEffect(() => {
    setData({
      ...data,
      firstName: loginProfileDataContext.state.owner.first_name,
      lastName: loginProfileDataContext.state.owner.last_name,
      emailAddress: loginProfileDataContext.state.owner.email,
      weightUnitProfile: loginProfileDataContext.state.owner.weight_unit,
    });
  }, [loginProfileDataContext]);

  const makeUpdateOwnerRequest = (formData) => {
    // console.log(formData)
    loadingContext.setLoading(true);
    // setData({ ...data, ...formData })
    let filteredData = {};
    filteredData._id = loginProfileDataContext.state.owner._id;

    if (loginProfileDataContext.state.owner.first_name !== formData.firstName) {
      filteredData.first_name = formData.firstName;
    } else {
      filteredData.first_name = loginProfileDataContext.state.owner.first_name;
    }
    if (loginProfileDataContext.state.owner.last_name !== formData.lastName) {
      filteredData.last_name = formData.lastName;
    } else {
      filteredData.last_name = loginProfileDataContext.state.owner.last_name;
    }
    if (loginProfileDataContext.state.owner.email !== formData.emailAddress) {
      filteredData.email = formData.emailAddress;
      setEmailUpdated(true);
    } else {
      filteredData.email = loginProfileDataContext.state.owner.email;
      setEmailUpdated(false);
    }
    if (
      loginProfileDataContext.state.owner.weight_unit !==
      formData.weightUnitProfile
    ) {
      filteredData.weight_unit = formData.weightUnitProfile;
    } else {
      filteredData.weight_unit =
        loginProfileDataContext.state.owner.weight_unit;
    }

    const dataToSend = filteredData;

    const config = {
      method: "post",
      url: `${process.env.GATSBY_BASE_URL}/api/v2/update-owner`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: dataToSend,
    };
    // Send Data to API
    axios(config)
      .then((response) => {
        // console.log(response)
        // setUpdateEmail(response?.data?.message && true)
        setResetPasswordReq(false);
        loadingContext.setLoading(false);

        if (
          response.data.error === true &&
          response.data.message.includes("email")
        ) {
          setEmailError(response.data.message);
        } else if (
          response.data.data.email_verified === false &&
          response.data.message === "Updated successfully"
        ) {
          setUpdateEmail(response?.data?.message && true);
          setUpdateOwnerResponse(response.data);
          setUpdateUserProfile(false);
          makeResendEmailRequest(filteredData.email);
        } else if (
          response.data.data.email_verified === true &&
          response.data.message === "Updated successfully"
        ) {
          modalContext.dispatch("hideUpdateUserProfileModal");
          setTimeout(() => {
            fetchDataContext();
          }, 1000);

          snackbarContext.setState({
            classes: "alert-success icon-success",
            content: "User's profile was updated successfully",
          });
        } else {
          setEmailError("");
        }
      })
      .catch((error) => {
        // console.log(error)
        loadingContext.setLoading(false);
        modalContext.dispatch("hideUpdateUserProfileModal");
      });
  };

  // const makeVerifyOldOtpRequest = (
  //   OTPValue,
  //   setOTPValue,
  //   setOTPMatched,
  //   setOTPNotMatched,
  //   setOTPResendEnable,
  //   setOTPTimerStart,
  //   setOTPTimerDuration
  // ) => {
  //   loadingContext.setLoading(true)
  //   const dataToSend = {
  //     enterOldEmail_suc: {
  //       authId: updateOwnerResponse.enterOldEmail_suc.authId,
  //       enterOldEmail_cookie:
  //         updateOwnerResponse.enterOldEmail_suc.enterOldEmail_cookie,
  //     },
  //     otp: OTPValue,
  //     req_fields: updateOwnerResponse.req_fields,
  //     _id: updateOwnerResponse._id,
  //   }

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-old-email-otp`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   }
  //   // Send Data to API
  //   axios(config)
  //     .then(response => {
  //       // console.log(response)
  //       loadingContext.setLoading(false)

  //       if (response.data.message === "Invalid OTP.") {
  //         setOTPNotMatched(true)
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 2 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 2 more failure(s).")
  //         setOTPNotMatched(true)
  //       } else if (
  //         response.data.message ===
  //         "Warning: You will be locked out after 1 more failure(s)."
  //       ) {
  //         setOTPError("You will be locked out after 1 more failure(s).")
  //         setOTPNotMatched(true)
  //       } else if (response.data.message === "User Locked Out.") {
  //         setOTPError(
  //           "Your account is temporarily locked. please retry in 5 minutes."
  //         )
  //         setOTPNotMatched(false)
  //         setOTPResendEnable(false)
  //         setOTPTimerStart(false)
  //         setOTPTimerDuration(30)
  //         removeTokenHandler()
  //       } else if (response.data.enterNewEmail_suc) {
  //         setOTPError("")
  //         setOTPNotMatched(false)
  //         setOTPValue("")
  //         setOTPTimerStart(true)
  //         setOTPResendEnable(false)
  //         setOldOtp(false)
  //         setNewOtp(true)
  //         setVerifyOldOTPResponse(response.data)
  //       } else {
  //         setOTPNotMatched(true)
  //       }
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //       loadingContext.setLoading(false)
  //       setOTPNotMatched(true)
  //     })
  // }

  // const makeVerifyNewOtpRequest = (
  //   OTPValue,
  //   setOTPValue,
  //   setOTPMatched,
  //   setOTPNotMatched,
  //   setOTPResendEnable,
  //   setOTPTimerStart,
  //   setOTPTimerDuration
  // ) => {
  //   loadingContext.setLoading(true)
  //   const dataToSend = {
  //     enterNewEmail_suc: {
  //       authId: verifyOldOTPResponse.enterNewEmail_suc.authId,
  //       enterNewEmail_cookie:
  //         verifyOldOTPResponse.enterNewEmail_suc.enterNewEmail_cookie,
  //     },
  //     otp: OTPValue,
  //     req_fields: verifyOldOTPResponse.req_fields,
  //     _id: verifyOldOTPResponse._id,
  //   }

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/verify-new-email-otp`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   }
  //   // Send Data to API
  //   axios(config)
  //     .then(response => {
  //       // console.log(response)
  //       loadingContext.setLoading(false)

  //       if (response.data.message === "Invalid OTP.") {
  //         setOTPNotMatched(true)
  //       } else if (response.data.owner_updated) {
  //         modalContext.dispatch("hideUpdateUserProfileModal")
  //         isLoginContext.setIsLogin(false)

  //         snackbarContext.setState({
  //           classes: "alert-success icon-success",
  //           content: "Your email address was changed successfully.",
  //         })
  //         setOTPValue("")
  //       } else {
  //         setOTPNotMatched(true)
  //       }
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //       loadingContext.setLoading(false)
  //       setOTPNotMatched(true)
  //     })
  // }

  // const makeResendNewOtpRequest = (
  //   OTPValue,
  //   setOTPValue,
  //   setOTPMatched,
  //   setOTPNotMatched
  // ) => {
  //   loadingContext.setLoading(true)
  //   const dataToSend = {
  //     verifyOldEmailOtp_suc: {
  //       authId: verifyOldOTPResponse.verifyOldEmailOtp_suc.authId,
  //       verifyOldEmailOtp_cookie:
  //         verifyOldOTPResponse.verifyOldEmailOtp_suc.verifyOldEmailOtp_cookie,
  //     },
  //     req_fields: verifyOldOTPResponse.req_fields,
  //     _id: verifyOldOTPResponse._id,
  //   }

  //   var config = {
  //     method: "post",
  //     url: `${process.env.GATSBY_BASE_URL}/api/v1/resend-otp-new-email`,
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     data: dataToSend,
  //   }
  //   // Send Data to API
  //   axios(config)
  //     .then(response => {
  //       // console.log(response)
  //       loadingContext.setLoading(false)
  //       if (response.data.message === "Invalid OTP.") {
  //         setOTPNotMatched(true)
  //       } else if (response.data.enterNewEmail_suc) {
  //         setVerifyOldOTPResponse({
  //           ...verifyOldOTPResponse,
  //           enterNewEmail_suc: {
  //             authId: response.data.enterNewEmail_suc.authId,
  //             enterNewEmail_cookie:
  //               response.data.enterNewEmail_suc.enterNewEmail_cookie,
  //           },
  //           req_fields: response.data.req_fields,
  //           _id: response.data._id,
  //         })
  //         setOTPValue("")
  //         setOldOtp(false)
  //         setNewOtp(true)

  //         // setOTPNotMatched(false)
  //       }
  //     })
  //     .catch(error => {
  //       // console.log(error)
  //       loadingContext.setLoading(false)
  //       setOTPNotMatched(true)
  //     })
  // }

  return (
    <Modal
      {...props}
      size="lg"
      centered
      animation
      scrollable
      onEntered={() => {
        resetPasswordContext.setState(true);
        tabRenderContext.setState(false);
      }}
      onExited={() => {
        if (updateEmail && resetPasswordContext.state && !updateUserProfile) {
          // loginProfileDataContext.setState({});
          isLoginContext.setIsLogin(false);
          localStorage.removeItem("token");
        }
        setUpdateUserProfile(true);
        setData(initialValues);
        resetPasswordContext.setState(false);
        setUpdateEmail(false);
        setResetPasswordReq(false);
        setEmailError("");
      }}
      backdrop="static"
    >
      <Modal.Header closeButton>
        <Modal.Title as="h6" className="text-mars-blue">
          {updateUserProfile || emailUpdated
            ? "User profile"
            : "Reset password"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {updateUserProfile ? (
          <UserProfile
            updateUserProfile={updateUserProfile}
            setUpdateUserProfile={setUpdateUserProfile}
            data={data}
            setData={setData}
            setChangeEmail={setChangeEmail}
            updateEmail={updateEmail}
            setUpdateEmail={setUpdateEmail}
            emailUpdated={emailUpdated}
            emailError={emailError}
            setEmailError={setEmailError}
            setEmailUpdated={setEmailUpdated}
            userInitialValues={initialValues}
            resetPassBtnClicked={resetPassBtnClicked}
            setResetPassBtnClicked={setResetPassBtnClicked}
            makeUpdateOwnerRequest={makeUpdateOwnerRequest}
          />
        ) : (
          // <OTPBox
          //   data={data}
          //   emailAddress={
          //     oldOtp
          //       ? loginProfileDataContext.state.owner.email
          //       : data.emailAddress
          //   }
          //   setIsLogin={props.setIsLogin}
          //   forgotPassword={true}
          //   title={
          //     oldOtp
          //       ? "For changing the email address, we need to verify the old email."
          //       : newOtp
          //       ? "For changing the email address, we need to verify the new email."
          //       : "To complete password reset process, we need to verify your email."
          //   }
          //   makeForgotPasswordRequest={makeForgotPasswordRequest}
          //   forgotPasswordResponseData={forgotPasswordResponseData}
          //   verifyOTPResponse={verifyOTPResponse}
          //   setVerifyOTPResponse={setVerifyOTPResponse}
          //   makeResetPasswordRequest={makeResetPasswordRequest}
          //   passwordNotChanged={passwordNotChanged}
          //   setPasswordNotChanged={setPasswordNotChanged}
          //   makeVerifyOtpRequest={makeVerifyOtpRequest}
          //   oldOtp={oldOtp}
          //   newOtp={newOtp}
          //   OTPError={OTPError}
          //   setOTPError={setOTPError}
          //   makeUpdateOwnerRequest={makeUpdateOwnerRequest}
          //   makeVerifyOldOtpRequest={makeVerifyOldOtpRequest}
          //   makeVerifyNewOtpRequest={makeVerifyNewOtpRequest}
          //   makeResendNewOtpRequest={makeResendNewOtpRequest}
          // />
          <EmailVerification
            data={data}
            emailAddress={data.emailAddress}
            setIsLogin={props.setIsLogin}
            forgotPassword={true}
            updateEmail={updateEmail}
            changeEmail={changeEmail}
            resetPasswordReq={resetPasswordReq}
            forgotPasswordResponseData={forgotPasswordResponseData}
            makeResetPasswordRequest={makeResetPasswordRequest}
            passwordNotChanged={passwordNotChanged}
            passwordError={passwordError}
            emailUpdated={emailUpdated}
            resetPassBtnClicked={resetPassBtnClicked}
            setPasswordNotChanged={setPasswordNotChanged}
            setResetPassBtnClicked={setResetPassBtnClicked}
            makeUpdateOwnerRequest={makeUpdateOwnerRequest}
            makeResendEmailRequest={makeResendEmailRequest}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        className={
          updateEmail && resetPasswordContext.state && !updateUserProfile
            ? "d-flex justify-content-center"
            : "d-flex justify-content-between"
        }
      >
        {updateUserProfile ? (
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={() => {
              props.onHide();
              setEmailError("");
            }}
          >
            Close
          </button>
        ) : updateEmail && resetPasswordContext.state && !updateUserProfile ? (
          ""
        ) : (
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={() => {
              setUpdateUserProfile(true);
              setResetPassBtnClicked(false);
              setEmailUpdated(false);
            }}
          >
            Back
          </button>
        )}
        {updateEmail && resetPasswordContext.state && !updateUserProfile ? (
          <button
            type="button"
            className="btn btn-outline-mars-blue"
            onClick={() => {
              props.onHide();
              // loginProfileDataContext.setState({});
              isLoginContext.setIsLogin(false);
              localStorage.removeItem("token");
            }}
          >
            Close
          </button>
        ) : (
          <button type="submit" className="btn btn-mars-blue" form="myform">
            Submit
          </button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default ModalUpdateUserProfile;
